import { checkResponse, getRequestInit, getUrl } from 'app/api/api'
import { IDocument, IDocumentDetail } from 'app/models/documents.model'
import { IFile } from 'app/models/files.model'

export const DocumentListQuery = async (enterprise: number): Promise<IDocument[]> => {
  return checkResponse(
    await fetch(getUrl(`v3/enterprises/${enterprise}/documents`), getRequestInit(true))
  )
}

export const DocumentDetailQuery = async (
  enterprise: number,
  id: number
): Promise<IDocumentDetail> => {
  return checkResponse(
    await fetch(getUrl(`v3/enterprises/${enterprise}/documents/${id}`), getRequestInit(true))
  )
}

export const DocumentDownloadQuery = async (
  enterprise: number,
  document: number
): Promise<IFile> => {
  return checkResponse(
    await fetch(getUrl(`v2/enterprises/${enterprise}/documents/${document}`), getRequestInit(true))
  )
}

export const TermsOfSalesDownloadQuery = async (termsOfSales?: number): Promise<IFile> => {
  return checkResponse(
    await fetch(
      getUrl(`termsofsales/${termsOfSales ? `?termsofsales=${termsOfSales}` : ''}`),
      getRequestInit(false)
    )
  )
}
