import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import Isotope from 'images/common/icon-footer.png'
import DocumentHelper from 'app/helpers/document.helper'
import {useApp} from "app/providers/app.provider"
import {useOnView} from "app/providers/onview.provider"
import 'app/css/footer.scss'

const Footer = (): JSX.Element => {
  const { t } = useTranslation()
  const { isConnected } = useApp()
  const { termsOfSalesDownloadUseMutation } = useOnView()

  const download = useCallback(async () => {
    const file = await termsOfSalesDownloadUseMutation?.mutateAsync(undefined)
    if (undefined !== file) {
      let blob = DocumentHelper.base64toBlob(file.content, file.mimetype)
      const fileUrl = URL.createObjectURL(blob)
      window.open(fileUrl, '_blank')
      URL.revokeObjectURL(fileUrl)
    }
  }, [])
  return (
    <div className="footer">
      <img src={Isotope} className='isotope-footer' alt='Flexo' />
      {isConnected && (
        <>
          <a className="underline" href="/reglement-interieur.pdf" target="_blank">
            {t('common.rule_procedure')}
          </a>&nbsp;|&nbsp;
          <a className="cursor-pointer underline" onClick={download}>
            {t('common.cg')}
          </a>
        </>
      )}
    </div>
  )
}

export default Footer
