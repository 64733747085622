
import LogoLogin from 'images/common/logo_login.svg'
import LogoLoginWhite from 'images/common/logo_login_white.svg'
import Logo from 'images/common/logo.svg'
import LogoWhite from 'images/common/logo_white.svg'
import BgImage from 'images/login/bgLogin.jpg'
import BgImageDark from 'images/login/bgLogin_dark.jpg'

export const IMAGES = {
    LOGO_LOGIN : LogoLogin,
    LOGO_LOGIN_WHITE : LogoLoginWhite,
    LOGO : Logo,
    LOGO_WHITE : LogoWhite,
    BG_LOGIN : BgImage,
    BG_LOGIN_DARK : BgImageDark,
}